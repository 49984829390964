import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { Link, useStaticQuery, graphql } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import {SectionHero, Section,SectionPageTitle, SectionImageBkg, Container, ListPointer, ListItemPointer } from "../components/Section"

const SectionBanner = styled(SectionHero)`
	position:relative;
  height: 100%;
  &:after{
    content: "";
    display:block;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    width:100%;
    height: 100%;    
  }
`
const HeroCaption = styled.div`	
  position:relative;
  z-index:1;
  width: 100%;
  padding:40px 0;
  @media (min-width:992px){
    padding:60px 0;
  }
  @media (min-width:1200px){
    padding:80px 0;
  }
  @media (min-width:1600px){
    padding:90px 0;
  }
	
`

const SitemapGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap:wrap;
  margin:0 -15px;
`

const SitemapItem = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  margin-bottom: 30px;
  @media(min-width:576px){
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media(min-width:992px){
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  & a {
    display: inline-block;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.7);
    &:hover {
      color: #000;
    }
  }
  & ul {
    font-size:16px;
    line-height:28px; 
    @media(min-width:1200px){
      font-size:18px;
      line-height:30px;
    }
    @media(min-width:1600px){
      font-size:20px;
      line-height:32px; 
    }
  }
`
const SiteMapTitle = styled.h2`
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 10px;
  & a {
    color: #000;
    &:hover {
      color: #fc0002;
    }
  }
`
const SiteMapPage = () => {
    const data = useStaticQuery(
        graphql`
          query {
            allContentfulProductCategory(sort: {order: ASC, fields: menuOrder}) {
              edges {
                node {
                  name
                  url
                }
              }
            }
          }
        `
    );
    return(
        <Layout>
        <SectionBanner className="section-hero">
            <SectionImageBkg><StaticImage src="../images/drawing-hero.jpg" alt="drawing-hero" /></SectionImageBkg>
            <HeroCaption>
            <Container>
                <SectionPageTitle className="h2" color="#fff" textAlign="left" mb="0">Sitemap</SectionPageTitle>
            </Container>
            </HeroCaption>
        </SectionBanner>

        <Section pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#fff" className="section-sitemap">       
            <Container>
            <SitemapGrid>
                <SitemapItem>
                <SiteMapTitle>Building Types</SiteMapTitle>
                <ListPointer>
                    {data.allContentfulProductCategory.edges.map((item) => (
                        <ListItemPointer key={item}><Link to={item.node.url}>{item.node.name}</Link></ListItemPointer>
                    ))}
                </ListPointer>
                </SitemapItem>
                <SitemapItem>
                <SiteMapTitle>Toolset</SiteMapTitle>
                <ListPointer>
                    <ListItemPointer><Link to="/faq">FAQs</Link></ListItemPointer>
                    <ListItemPointer><Link to="/reviews">Reviews</Link></ListItemPointer>
                    <ListItemPointer><Link to="/ordering-process">Ordering Process</Link></ListItemPointer>
                    <ListItemPointer><Link to="/site-preparation">Site Preparation & Installation</Link></ListItemPointer>
                    <ListItemPointer><Link to="/roofstyle">Roof Styles</Link></ListItemPointer>
                    <ListItemPointer><Link to="/color-options">Color Options</Link></ListItemPointer>
                    <ListItemPointer><Link to="/doors-windows-accessories">Doors, Windows, & Accessories</Link></ListItemPointer>
                    <ListItemPointer><Link to="/drawings-permits">Drawings & Permits</Link></ListItemPointer>
                    <ListItemPointer><Link to="/coverage-lead-times">Coverage Area & Lead Times</Link></ListItemPointer>
                </ListPointer>
                </SitemapItem>
                <SitemapItem>
                <SiteMapTitle>Quick Links</SiteMapTitle>
                <ListPointer>
                    <ListItemPointer><Link to="/become-dealer">Become A Dealer</Link></ListItemPointer>
                    <ListItemPointer><Link to="/about-us">About Us</Link></ListItemPointer>
                    <ListItemPointer><Link to="/contact">Contact</Link></ListItemPointer>
                    <ListItemPointer><Link to="/photos">Photos</Link></ListItemPointer>
                    <ListItemPointer><a href="https://tubularbuildings.sensei3d.com/" target="_blank" rel="noreferrer">Design in 3D</a></ListItemPointer>
                </ListPointer>
                </SitemapItem>
            </SitemapGrid>
            </Container>
        </Section>
        </Layout>
    )
}
export default SiteMapPage